import React from "react"
import Layout from "../../components/layout"

export default function Blog({ data }) {
	return (
		<Layout>
			<article className="blog">
				<header className="entry-header">
					<h1 className="entry-title">Geeks Unite with Ensemble Programming</h1>
					<div className="entry-meta">
						<span className="sep">Posted on </span>
						<time className="entry-date">June 15, 2021</time>
					</div>
				</header>
				<div className="entry-content">
					<p><strong>Geeks unite! Code is a universal language that can bring the world together.</strong></p>

					<p>I had the pleasure of experiencing this first hand when I attended a <a href="https://global-liquid-mob.github.io/">Global Liquid Mob</a> meetup with <a href="https://www.linkedin.com/in/boballenagilist/">Bob Allen</a> this past weekend. The premise of this meetup is simple. Let's learn mob (aka ensemble) programming. Let's volunteer our time to an open source project. Let's work as a fully distributed team across the globe working on the same project for 24 hrs!</p>

					<p>People can come and leave as they see fit but over the span of 24 hrs, we'll work collectively as a team to help further along an open source project for a good cause. And hopefully increase our skills as developers as we learn mob programming, TDD and working collectively and collaboratively.</p>

					<p>Having never joined a live coding session with strangers, I did not know what to expect.</p>

					<p>Would I be accepted?</p>

					<p>Would I be judged a good enough developer?</p>

					<p>Was I about to walk into the lion's den with a bunch of brilliant jerks?</p>

					<p>What the heck did I get myself into?</p>

					<p>As I join the zoom session, the room is filled with 6 complete strangers and my imposter syndrome kicks in. I sit there nervously hovering my mouse over the "leave" button debating on whether to click or not when I am greeted by Bob Allen and his welcoming kind smile. Bob begins to go over the process of Mob Programming and introduces <a href="https://www.linkedin.com/in/jtfairbank/">Taylor Fairbank</a>, co-founder and ops director for <a href="https://www.linkedin.com/company/distribute-aid/">Distribute Aid</a>. Distribute Aid builds free technology tools to support refugee aid groups, and we'll be working on one of their open source projects for the meetup.</p>

					<p>We do a quick round robin of introductions. There are already people around the globe. From the States, Germany, Australia, Serbia, Philippines and Canada (represent!) and we'll be expecting more to join and drop off throughout the night. We're hoping this project goes around the globe for 24 hrs.</p>

					<p>Taylor does a quick walkthrough the code base and quickly decides on the first task we'll work on collectively. The project is using Gatsby, GraphQL, Typescript and ReactJS. Individually, none of us have complete knowledge of each component in this stack. But collectively, we do!</p>

					<p>We begin to start mob programming with each other. Taking on the role of Driver or Navigator or spectator, switching turns every couple of minutes. This group is very welcoming. As we're coding, we begin sharing knowledge building each of our skill sets in new domains. I'm brand new to GraphQL, so someone shows me how to build a query! Others are unfamiliar with javascript, they come from a python background. So we start speaking universally to one another. This is an array in Javascript. This is an object with properties. Let's create a variable!</p>

					<p>And then it hits me. No matter where we are physically sitting in the world, we're able to communicate in such a unique and special way! Through code! We're building something for a good cause and we're learning from one another. Everyone is so kind and giving of their time and knowledge. And I'm taken back to when I first started coding. Way back in time, when I was at university working with my peers collectively learning from one another and showing each other the cool things we just discovered either in class or on the internet. It was an absolute blast, something I haven't experienced in quite a long while. We're coding JUST FOR FUN! We're coding socially. Complete strangers on the internet and we're bonding.</p>

					<p>As the night continues, it's getting later and later, and I need to go to sleep. But I'm thoroughly enjoying myself and finding it hard to sign off!</p>

					<p>I wake up the next morning eager to see where the project has evolved. There are new people in the zoom from other locations across the world! Damn, this project has not stopped. It has continued for 12 hours across the globe! Now the project has unit tests and we're working on creating some tests for the code we implemented the night before. Again, everyone is so welcoming and forthcoming with their time and knowledge. I'm able to jump right back into the group and continue learning with the rest of them.</p>

					<p>Eventually the 24 hrs are up and it's time to say goodbye. We do a quick retrospective and the feeling is mutual across the group. Everyone enjoyed themselves. Everyone loved the collaborative nature of mob programming and the inclusiveness shared within this group.</p>

					<p>There were no brilliant jerks! Just a bunch of brilliant, kind people!</p>

					<p>Thank you <a href="https://www.linkedin.com/in/boballenagilist/">Bob Allen</a> and <a href="https://www.linkedin.com/in/jtfairbank/">Taylor Fairbank</a> for organizing this meetup! It was an absolute surprise and joy to experience.</p>

					<p>For those who want to learn more, check out:</p>
					<ul>
						<li>The <a href="https://global-liquid-mob.github.io/">premise of the meetup</a> and <a href="https://www.eventbrite.com/o/code-craft-saturdays-and-sundays-15457506392">signup at the next event</a></li>
						<li>Or learn about <a href="https://distributeaid.org/">Distribute Aid</a> and help with their <a href="https://github.com/distributeaid/distributeaid.org">open source projects</a>.</li>
					</ul>

					<p>Happy Social Coding!</p>
				</div>
			</article>
		</Layout>
	)
}

